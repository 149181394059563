/*
  This pack is being used to seperate locally installed vendor files. We should be trying to migrate these
  to the main bundle by using their NPM versions OR by removing them as dependencies completely.
*/

import 'sass/vendor'

import 'js/vendor/jquery.cookie'
import 'js/vendor/jquery.fineuploader'
import 'js/vendor/jquery.mask'
import 'js/vendor/jquery.uniform.min'
import 'js/vendor/croppie'
import 'js/vendor/hammer'
import 'js/vendor/unslider'
import 'js/vendor/mailcheck'
import 'js/vendor/simple_upload'
import 'js/vendor/images_loaded'

/*
  The reason we import rails here rather than in the application bundle is because the gem_vendor_scripts
  requires it. Once we remove all gem vendor scripts, we can safely move this to the application bundle
  and stop accessing it from the window.
*/
import Rails from '@rails/ujs'
Rails.start()
